<!-- this is copied over from shuriken-ui because there are some dev  texts in the original that shows up in the UI
https://github.com/shuriken-ui/nuxt/issues/92
-->
<script setup lang="ts">
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/vue'

const props = withDefaults(
  defineProps<{
    /**
     * The items to display in the multiselect.
     */
    items: any[]

    /**
     * The (non-model) value
     */
    value?: any

    /**
     * The model value of the multiselect.
     *
     * @modifiers
     * `v-model="value"`
     *
     * @modifiers
     * the value property of an object (as defined in properties.value) rather than the object itself
     * `v-model.prop="value"`
     */
    modelValue?: any

    /**
     * Used internaly to allow v-model.number and v-model.trim
     */
    modelModifiers?: any

    /**
     * The shape of the multiselect.
     */
    shape?: 'straight' | 'rounded' | 'smooth' | 'curved' | 'full'

    /**
     * The label to display for the multiselect.
     */
    label?: string

    /**
     * If the label should be floating.
     */
    labelFloat?: boolean

    /**
     * Whether the multiselect is in a loading state.
     */
    loading?: boolean

    /**
     * An error message or boolean value indicating whether the input is in an error state.
     */
    error?: string | boolean

    /**
     * Whether the multiselect is disabled.
     */
    disabled?: boolean

    /**
     * The icon to display for the multiselect.
     */
    icon?: string

    /**
     * The icon to show when the component is selected.
     */
    selectedIcon?: string

    /**
     * Whether the multiselect allows multiple selections.
     */
    multiple?: boolean

    /**
     * The label to display for multiple selections, or a function that returns the label.
     * When this is undefined, the default multiple label function defined below will be used.
     */
    multipleLabel?:
      | string
      | ((value: any[], labelProperty?: string) => string)
      | undefined

    /**
     * The placeholder text to display when no selection has been made.
     */
    placeholder?: string

    /**
     * The size of the listbox.
     */
    size?: 'sm' | 'md' | 'lg'

    /**
     * The contrast of the listbox.
     */
    contrast?: 'default' | 'default-contrast' | 'muted' | 'muted-contrast'

    /**
     * The properties to use for the value, label, sublabel, media, and icon of the options items.
     */
    properties?: {
      /**
       * The property to use for the value of the options.
       */
      value?: string

      /**
       * The property to use for the label of the options.
       * A factory for the label is also allowed.
       */
      label?: string | ((item: any) => string | undefined)

      /**
       * The property to use for the sublabel of the options.
       */
      sublabel?: string

      /**
       * The property to use for the media of the options.
       */
      media?: string

      /**
       * The property to use for the icon of the options.
       */
      icon?: string
    }
  }>(),
  {
    icon: '',
    value: undefined,
    modelValue: undefined,
    modelModifiers: () => ({}),
    selectedIcon: 'lucide:check',
    label: '',
    placeholder: '',
    size: 'md',
    contrast: 'default',
    shape: undefined,
    error: false,
    multipleLabel: undefined,
    multiple: false,
    loading: false,
    disabled: false,
    properties: () => ({}),
  },
)

const emits = defineEmits<{
  (event: 'change', value?: any): void
  (event: 'update:modelValue', value?: any): void
}>()

const { t } = useI18n({
  useScope: 'local',
})

const getLabel = (value: any) => {
  if (props.properties.label) {
    if (typeof props.properties.label === 'function') {
      const label = props.properties.label(value)
      if (!label) {
        console.error(
          `No Listbox label returned from label function for value`,
          value,
        )
      }
      return label
    }
    return value[props.properties.label]
  }
  else if (props.properties.value) {
    return value[props.properties.value]
  }
  else {
    return value
  }
}

// TODO: figure out what the idea behind labelProperty.
// I think we don't need it anymore, actually. `getLabel` already handles this for us.
function defaultMultipleLabel(value: any[], labelProperty?: string): string {
  if (value.length === 0) {
    if (props.placeholder) {
      return '' // The placeholder is already shown, so we can return an empty string here
    }
    return t('noItemsSelected')
  }
  else if (value.length > 1) {
    return t('countItemsSelected', { count: value.length })
  }
  // If there's just 1 value selected.
  // Just get the label as if it was a single select?
  return getLabel(value?.[0])
  // Previously this was:
  // return labelProperty
  //   ? String(value?.[0]?.[labelProperty])
  //   : String(value?.[0])
}
const multipleLabel = props.multipleLabel ?? defaultMultipleLabel

const appConfig = useAppConfig()
const shape = computed(() => props.shape ?? appConfig.nui.defaultShapes?.input)

const shapeStyle = {
  straight: '',
  rounded: 'nui-listbox-rounded',
  smooth: 'nui-listbox-smooth',
  curved: 'nui-listbox-curved',
  full: 'nui-listbox-full',
}
const sizeStyle = {
  sm: 'nui-listbox-sm',
  md: 'nui-listbox-md',
  lg: 'nui-listbox-lg',
}
const contrastStyle = {
  'default': 'nui-listbox-default',
  'default-contrast': 'nui-listbox-default-contrast',
  'muted': 'nui-listbox-muted',
  'muted-contrast': 'nui-listbox-muted-contrast',
}

// Reactive value that syncs with props.value or props.modelValue
const value = ref<any>(props.modelValue ?? props.value)
// Watch props.modelValue to update the reactive value
watch(
  () => props.modelValue,
  (newValue) => {
    // Check if value has changed, including when it's an array
    const hasChanged = JSON.stringify(newValue) != JSON.stringify(value.value)
    if (hasChanged) {
      value.value = newValue
    }
  },
)

// Watch props.value to update the reactive value if modelValue is not used
watch(
  () => props.value,
  (newValue) => {
    // Check if value has changed, including when it's an array
    const hasChanged = JSON.stringify(newValue) != JSON.stringify(value.value)
    if (hasChanged) {
      value.value = newValue
    }
  },
)

// Nasty workaround for v-bind and v-model usage
const isUsingVbind = !!useAttrs().onChange || !!useAttrs().onInput
watch(value, (val) => {
  if (isUsingVbind) {
    // console.log(`Emit change => `, val)
    emits('change', val)
  }
  else {
    // console.log(`Emit update:modelValue => `, val)
    emits('update:modelValue', val)
  }
})

const placeholder = computed(() => {
  if (props.loading) {
    return
  }
  if (props.labelFloat) {
    return props.label
  }

  return props.placeholder
})

// const computedValue = computed(() => {
//   if (props.modelModifiers.prop && props.properties.value) {
//     const attr = props.properties.value
//     return props.items.find((i) => i[attr] === value.value)
//   }
//   return value.value
// })
</script>

<template>
  <div
    class="nui-listbox"
    :class="[
      contrastStyle[props.contrast],
      sizeStyle[props.size],
      shape && shapeStyle[shape],
      props.error && !props.loading && 'nui-listbox-error',
      props.loading && 'nui-listbox-loading',
      props.labelFloat && 'nui-listbox-label-float',
      props.icon && 'nui-has-icon',
    ]"
  >
    <Listbox
      v-slot="{ open }: { open: boolean }"
      v-model="value"
      :by="props.modelModifiers.prop ? undefined : props.properties.value"
      :multiple="props.multiple"
      :disabled="props.disabled"
    >
      <ListboxLabel
        v-if="
          ('label' in $slots && !props.labelFloat) ||
            (props.label && !props.labelFloat)
        "
        class="nui-listbox-label"
      >
        <slot name="label">
          {{ props.label }}
        </slot>
      </ListboxLabel>

      <div class="nui-listbox-outer">
        <ListboxButton :disabled="props.disabled" class="nui-listbox-button">
          <slot
            name="listbox-button"
            :value="value"
            :open="open"
          >
            <div class="nui-listbox-button-inner">
              <BaseIconBox
                v-if="props.icon"
                size="xs"
                shape="rounded"
                class="nui-icon-box"
              >
                <Icon :name="props.icon" class="nui-icon-box-inner" />
              </BaseIconBox>

              <template v-if="Array.isArray(value)">
                <div
                  v-if="value.length === 0 && placeholder"
                  class="nui-listbox-placeholder"
                  :class="props.loading && 'select-none text-transparent'"
                >
                  {{ placeholder }}
                </div>
                <div
                  class="block truncate text-left"
                  :class="[
                    props.loading && 'select-none text-transparent',
                    value.length === 0 && 'text-muted-300 dark:text-muted-500',
                  ]"
                >
                  {{
                    typeof multipleLabel === 'function'
                      ? multipleLabel(value)
                      : multipleLabel
                  }}
                </div>
              </template>

              <template v-else-if="value">
                <BaseAvatar
                  v-if="props.properties.media && value[props.properties.media]"
                  :src="value[props.properties.media]"
                  size="xs"
                  class="-ms-2 me-2"
                />
                <BaseIconBox
                  v-else-if="
                    props.properties.icon && value[props.properties.icon]
                  "
                  size="xs"
                  shape="rounded"
                  class="-ms-2 me-2"
                >
                  <Icon :name="value[props.properties.icon]" class="size-4" />
                </BaseIconBox>
                <div
                  class="truncate text-left"
                  :class="props.loading && 'select-none text-transparent'"
                >
                  {{ getLabel(value) }}
                </div>
              </template>

              <template v-else>
                <div
                  class="nui-listbox-placeholder"
                  :class="props.loading && 'select-none text-transparent'"
                >
                  {{ placeholder }}
                </div>
              </template>

              <span class="nui-listbox-chevron">
                <Icon
                  name="lucide:chevron-down"
                  class="nui-listbox-chevron-inner"
                  :class="[open && 'rotate-180']"
                />
              </span>

              <div v-if="props.loading" class="nui-listbox-placeload">
                <BasePlaceload class="nui-placeload" />
              </div>
            </div>
          </slot>
        </ListboxButton>

        <Transition
          leave-active-class="transition duration-100 ease-in"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <ListboxOptions class="nui-listbox-options">
            <ListboxOption
              v-for="item in props.items"
              v-slot="{ active, selected }"
              :key="
                props.properties.value ? item[props.properties.value] : item
              "
              :value="
                props.modelModifiers.prop && props.properties.value
                  ? item[props.properties.value]
                  : item
              "
              as="template"
            >
              <li
                class="nui-listbox-option group/nui-listbox-option"
                :class="[active && 'nui-active']"
              >
                <slot
                  name="listbox-item"
                  :item="item"
                  :open="open"
                  :active="active"
                  :selected="selected"
                >
                  <BaseAvatar
                    v-if="
                      props.properties.media && item[props.properties.media]
                    "
                    :src="item[props.properties.media]"
                    size="xs"
                  />
                  <BaseIconBox
                    v-else-if="
                      props.properties.icon && item[props.properties.icon]
                    "
                    size="sm"
                    shape="rounded"
                  >
                    <Icon
                      :name="item[props.properties.icon]"
                      class="text-muted-400 group-hover/nui-listbox-option:text-primary-500 size-5 transition-colors duration-200"
                    />
                  </BaseIconBox>

                  <div class="nui-listbox-option-inner">
                    <BaseHeading
                      as="h4"
                      :weight="selected ? 'semibold' : 'normal'"
                      size="sm"
                      class="nui-listbox-heading"
                    >
                      {{ getLabel(item) }}
                    </BaseHeading>
                    <BaseText
                      v-if="
                        props.properties.sublabel &&
                          item[props.properties.sublabel]
                      "
                      size="xs"
                      class="nui-listbox-text"
                    >
                      {{ item[props.properties.sublabel] }}
                    </BaseText>
                  </div>
                  <span v-if="selected" class="nui-listbox-selected-icon">
                    <Icon
                      :name="selectedIcon"
                      class="nui-listbobx-selected-icon-inner"
                    />
                  </span>
                </slot>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </Transition>

        <ListboxLabel
          v-if="
            ('label' in $slots && props.labelFloat) ||
              (props.label && props.labelFloat)
          "
          class="nui-label-float"
        >
          <slot name="label">
            {{ props.label }}
          </slot>
        </ListboxLabel>

        <span
          v-if="props.error && typeof props.error === 'string'"
          class="text-danger-600 mt-1 block font-sans text-[0.65rem] font-medium leading-none"
        >
          {{ props.error }}
        </span>
      </div>
    </Listbox>
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "noItemsSelected": "No {itemsLabel} selected",
    "countItemsSelected": "{count} {itemsLabel} selected"
  },
  "nl": {
    "noItemsSelected": "Geen {itemsLabel} geselecteerd",
    "countItemsSelected": "{count} {itemsLabel} geselecteerd"
  }
}
</i18n>
